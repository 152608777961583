import noUiSlider from 'nouislider';

document.addEventListener("DOMContentLoaded", function(event) {
  const sliders = document.querySelectorAll(".js-number-property-slider");
  
  sliders.forEach((slider, index) => {
    const minValueInput = slider.querySelector(".js-min-value-input");
    const maxValueInput = slider.querySelector(".js-max-value-input");
    const sliderData = slider.querySelector(".js-number-property-slider-data");
    
    if (minValueInput !== null || maxValueInput !== null) {
      let minValue = parseInt(sliderData.dataset.min_value);
      const maxValue = parseInt(sliderData.dataset.max_value);
      let startValue = parseInt(sliderData.dataset.start_value);
      const endValue = parseInt(sliderData.dataset.end_value);

      if (minValue === maxValue) { minValue = minValue - 1 }
      if (startValue === endValue) { startValue = startValue - 1 }
      
      noUiSlider.create(sliderData, {
        start: [startValue, endValue],
        connect: true,
        range: {
          'min': minValue,
          'max': maxValue
        },
        pips: {
          mode: 'values',
          values: [minValue, (minValue + maxValue) / 2, maxValue],
          density: 1000
        }
      });
      
      sliderData.noUiSlider.on('update', function (values, handle) {
        minValueInput.value = parseInt(values[0]);
        maxValueInput.value = parseInt(values[1]);
      })

      minValueInput.addEventListener('change', function () {
        sliderData.noUiSlider.set([this.value, null]);
      });

      maxValueInput.addEventListener('change', function () {
        sliderData.noUiSlider.set([null, this.value]);
      });
    }
  });
});
