showMoreSimilar = function() {
  const similarKit = document.querySelector('.js-similar-kit');
  const button = similarKit.querySelector('.js-similar-kit-button');
  const similarProducts = similarKit.querySelectorAll('.similar-product')
  
  similarProducts.forEach(function(item) {
    item.classList.remove('hidden');
  });
  
  button.classList.add('hidden');
};

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-similar-kit-button', 'click', showMoreSimilar);
