import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

window.swiperInit = function () {
  const similarSwiper = new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    slidesPerView: 3,
    loop: false,
    slidesOffsetAfter: 0,
    breakpoints: {
      0: {
        slidesPerView: 4,
      },
      620: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 4,
      },
      1280: {
        slidesPerView: 3,
      },
      1600: {
        slidesPerView: 3,
      }
    }
  });
  
  const swiper = new Swiper('.swiper-container', {
    modules: [Navigation, Pagination],
    slidesPerView: 5,
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      620: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 5,
      }
    }
  });
}

document.addEventListener("DOMContentLoaded", function(event) {
  swiperInit();
});

window.addEventListener("jsRendered", function(event) {
  swiperInit();
});
