import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from "prop-types";
import parse from 'html-react-parser';


const ProductDetailedMenu = ({html_menu}) => {
  const [isVisible, setIsVisible] = useState(false);
  const htmlMenu = { __html: html_menu }
  
  const toggleMEnu = () => {
    setIsVisible(!isVisible)
  }
  
  return (
    <div className='mobile-detailed-menu-wrapper'>
      <div className='mobile-detailed-menu-button-wrapper'>
        <button onClick={toggleMEnu} className="mobile-detailed-menu-toggle" />
      </div>
      <Modal isOpen={isVisible} ariaHideApp={false} className="content" overlayClassName="detailed-menu-modal-overlay">
        <div className='detailed-menu-modal'>
          <div className="detailed-menu-modal__close-wrapper">
            <button className="detailed-menu-modal__close"
                    onClick={toggleMEnu}/>
          </div>
          <div onClick={toggleMEnu}
               className='product__detailed-menu product__mobile-detailed-menu'>
            {parse(html_menu)}
          </div>
        </div>
      </Modal>
    </div>
  )
}

ProductDetailedMenu.prototypes = {
  html_menu: PropTypes.string.isRequired
}

export default ProductDetailedMenu
