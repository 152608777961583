import React, { Component } from 'react';
import PropTypes from "prop-types";
import '../../helpers/auth-headers'
import { authHeaders } from '../../helpers/auth-headers';


class Cart extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      count: this.props.count,
      isSubmitting: false
    };
  }
  
  increaseCount = () => {
    let count = this.state.count + 1
    
    this.setState({
      count: count,
      isSubmitting: true
    })
    this.submitToCart(count);
  }
  
  decreaseCount = () => {
    let count = this.state.count - 1

    this.setState({
      count: count,
      isSubmitting: true
    })
    this.submitToCart(count);
  }
  
  submitToCart = async (count) => {
    if (this.state.isSubmitting === true) { return; };
    
    const { url } = this.props;
    
    const method = (count === 0) ? 'DELETE' : 'PATCH'
    
    const data = new FormData();
    data.append(`quantity`, count);

    fetch(url, {
      body: data,
      method: method,
      headers: authHeaders(),
      credentials: 'same-origin'
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      window.changeCartCounterValue(data.count)
      this.setState({ isSubmitting: false })
    })
    .catch(error => {
      this.setState({ errors: error })
      console.log(error)
    })
  }
  
  render() {
    const { count, isSubmitting } = this.state;
    
    if (count === 0) {
      return (
        <div
          disabled={isSubmitting}
          title='Добавить товар в корзину'
          className="product-cart__button"
          onClick={this.increaseCount}>
          <span className='product-cart__add-to-cart'>В корзину</span>
        </div>
      )
    } else {
      return (
        <div className='product-cart'>
          <div className="product-cart-number-selectors">
            <button
              disabled={isSubmitting}
              className="product-cart-number-selectors__minus"
              onClick={this.decreaseCount}>
              -
            </button>
            <input
              className="product-cart-number-selectors__input"
              readOnly={true}
              value={count}
              type="number" min="1" max="999"
              name="quantity"
            />
            <button
              className="product-cart-number-selectors__plus"
              onClick={this.increaseCount}>
              +
            </button>
          </div>
          <a
            title='Перейти в корзину'
            href='/cart'
            className='product-cart__button'>
            <span className='product-cart__to-cart'>В корзине</span>
          </a>
        </div>
      )
    }
  }
}


Cart.prototypes = {
  count: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
}

export default Cart
