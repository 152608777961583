import React, { Component } from 'react';
import PropTypes from "prop-types";
import ProductDocumentModal from './ProductDocumentModal';

class ProductDocuments extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const { documents } = this.props;
    
    return(
      <div className='product__files'>
        {documents.map((document, index) =>
          <ProductDocumentModal document={document} key={index}/>
        )}
      </div>

    )
  }
}

ProductDocuments.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ProductDocuments
