import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) { Rails.start(); }

let componentRequireContext = require.context("javascripts/components", true);
let ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import 'nouislider/dist/nouislider.css';
import '../css/application.sass';
import '../javascripts/helpers/ResizeSensor'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

require.context('../fonts', true)
require.context('../images', true)

import "../javascripts/globalEventListener"
import "../javascripts/overlay"
import "../javascripts/category-menu"
import "../javascripts/sticky-header"
import "../javascripts/slider-init"
import "../javascripts/number-property-slider"
import "../javascripts/product-variant-switcher"
import "../javascripts/product-quantity-change"
import "../javascripts/categories-filter"
import "../javascripts/swiper-init"
import "../javascripts/company-map"
import "../javascripts/cart"
import "../javascripts/search"
import "../javascripts/yandex-mertika"
import '../javascripts/properties-slider-init'
import '../javascripts/show-more'
import '../javascripts/show-more-similar'
import '../javascripts/scroll-top'
