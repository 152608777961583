import React, { Component } from 'react';
import PropTypes from "prop-types";
import Modal from 'react-modal';
import classNames from 'classnames';


class ProductDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isHover: false
    };
  }
  
  openModal = () => {
    document.body.style.overflow = 'hidden';
    this.setState({ isOpen: true })
  }
  
  closeModal = () => {
    this.setState({ isOpen: false, isHover: false })
    document.body.style.overflow = 'unset';
  }
  
  setHover = () => {
    this.setState({ isHover: true })
  }
  
  removeHover = () => {
    this.setState({ isHover: false })
  }
  
  render() {
    const { isOpen, isHover } = this.state;
    const { document } = this.props;
    const wrapperClasses = classNames('product__files__file-wrapper', isHover ? 'hover' : '' )
    
    return(
      <div className={wrapperClasses}
           onMouseEnter={this.setHover} onMouseLeave={this.removeHover}>
        <div className="product__files-file-info" onClick={this.openModal}>
          <div className="product__files__file-name">
            <a download={document.name}
               href={document.file_url}>{document.name}</a>
          </div>
          <div className="product__files__file-actions">
            <div className="product__files__file-size">
              {document.file_size}
            </div>
            <div className="product__files__download">
              <span></span>
              Открыть
            </div>
          </div>
        </div>
        <div onClick={this.openModal}>
          <div className="product__files__file-extension">
            pdf
          </div>
        </div>
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          className="document-modal"
          overlayClassName="document-modal-overlay">
          <div className="document-modal__close-wrapper">
            <button className="document-modal__close"
                    onClick={this.closeModal}/>
          </div>
          <h4 className="document-modal__header">
            {document.name}
          </h4>
          <div className="document-modal__content">
            <embed
              width="100%" height="100%"
              src={document.url}
              type="application/pdf"
            />
          </div>
        
        </Modal>
      </div>
    
    )
  }
}

ProductDocumentModal.prototypes = {
  document: PropTypes.shape({
    extension: PropTypes.string.isRequired,
    file_size: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}

export default ProductDocumentModal;
